import _ from 'lodash'

import { env } from '@/env'
import { changeLocale } from '@/logic/i18n'

import { getRepo, getRepos } from '@/repos'
import { createConsumer } from '@/logic/socket/consumer'
import { createMessagingChannel } from '@/logic/socket/MessagingChannel'

const useAuthStore = defineStore(
  'auth',
  () => {

    const user = ref(null)
    const token = ref(null)
    const settings = ref({})
    const socketConsumer = ref(null)

    const userRepo = getRepo('users')
    const personRepo = getRepo('people')
    const emotionRepo = getRepo('emotions')
    const circleCategoryRepo = getRepo('circle_categories')
    const circleRepo = getRepo('circles')
    const targetRepo = getRepo('targets')

    const isSignedIn = computed(() => !! user.value)
    const isAdmin = computed(() => {
      const adminEmail = env.ADMIN_EMAIL
      if (adminEmail.length > 0) {
        return user.value?.email == adminEmail
      } else {
        return false
      }
    })

    function setUser(value) {
      user.value = value
    }
    function setToken(value) {
      token.value = value
    }
    function setSettings(value) {
      settings.value = value
    }
    function setSetting(key, value) {
      settings.value[key] = value
      settings.hasChanged = true
    }
    function setSocketConsumer({ token }) {
      const consumer = createConsumer({ token })
      const userId = user.value.id
      createMessagingChannel({ consumer, userId })
      socketConsumer.value = consumer
    }
    function unsetSocketConsumer() {
      socketConsumer.value.disconnect()
      socketConsumer.value = null
    }
    function signOut() {
      setUser(null)
      setToken(null)
    }
    function signIn({ token, user }) {
      if (token) {
        setToken(token)
        setUser(user)
      }
    }
    function signUp({ token, user }) {
      signIn({ token, user })
    }

    watch(() => settings.value.locale, (value) => {
      changeLocale(value)
    })

    watch(() => user.value, (newValue, _oldValue) => {
      if (newValue == null) {
        console.log('onSignOut')
        unsetSocketConsumer()
        destroyAllUserData()
      }
    })

    watch(() => user.value?.id, (newValue, _oldValue) => {
      if (newValue) {
        console.log('onSignIn')
        fetchAllUserData()
        setSocketConsumer({ token: token.value })
      }
    })

    async function fetchAllUserData() {
      if (!isSignedIn.value) return Promise.resolve()

      await Promise.allSettled([
        personRepo.api().readMe(),
        personRepo.api().readMeWithData(),
        emotionRepo.api().readAll(),
        circleCategoryRepo.api().readAll(),
        circleRepo.api().readAll(),
        // targetRepo.api().readAll(),
      ])
    }

    async function destroyAllUserData() {
      _.each(getRepos(), (repo) => {
        repo.fresh([])
      })
    }

    return {
      user,
      token,
      settings,
      isSignedIn,
      isAdmin,
      setUser,
      setToken,
      setSettings,
      setSetting,
      signIn,
      signOut,
      signUp,
      fetchAllUserData,
    }
  },
  {
    persist: {
      key: '__persisted__auth',
      pick: ['user', 'token', 'settings'],
    },
  },
)

export { useAuthStore }
